<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Добавление водителя
    </h4>
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Вид перевозки"
              >
                <b-form-radio-group v-model="accessType">
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="auto"
                    @change="clearCheckType()"
                  >
                    Автоперевозка
                  </b-form-radio>
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="container"
                    @change="clearCheckType()"
                  >
                    ЖД перевозка (только на ЖД рейсы на доставку контейнера до ЖД станции)
                  </b-form-radio>
                  <b-form-radio
                    v-model="accessType"
                    name="accessType"
                    value="cargoFlow"
                    @change="clearCheckType()"
                  >
                    Услуга грузопровод (только по услуги грузопровод)
                  </b-form-radio>
                  <b-form-input
                    v-model="accessType"
                    disabled
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="accessType==='auto'">
            <b-col md="12">
              <b-form-group
                label="Тип проверки"
              >
                <b-form-radio-group v-model="checkType">
                  <b-form-radio
                    v-model="checkType"
                    name="checkType"
                    :value="0"
                  >
                    Обычная проверка (личное посещение водителя СБ ПЭК – обычный пакет документов для проверки)
                  </b-form-radio>
                  <b-form-radio
                    v-if="expressDriverCheck"
                    v-model="checkType"
                    name="checkType"
                    :value="1"
                  >
                    Экспресс проверка (без личного посещения водителя СБ ПЭК – расширенный пакет документов)
                  </b-form-radio>
                  <b-form-input
                    v-model="checkType"
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="checkType===0">
            <b-col md="4">
              <b-form-group
                class="form-group-el-select required"
                label="Отделение ПЭК для посещения его водителем для окончательной проверки"
              >
                <b-form-input
                  v-model.trim="checkLocation"
                  disabled
                  class="d-none"
                />
                <el-select
                  id="checkLocation"
                  v-model="checkLocation"
                  class="form-control"
                  name="checkLocation"
                  placeholder="Выберите отделение"
                  clearable
                >
                  <el-option
                    v-for="item in driverCheckLocations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <drivers-add
            v-if="mainFormAvailable"
            :check-type="checkType"
            :check-location="checkLocation"
            :access-type="accessType"
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import DriversAdd from '@/views/Drivers/DriversAdd';
import {contractorRead, driverCheckLocations} from '@/services/api';
export default {
  name: 'DriversAddFirstStep',
  components: {DriversAdd},
  data() {
    return {
      step: 1,
      checkType: null,
      checkLocation: null,
      accessType: null,
      driverCheckLocations: [],
      expressDriverCheck: false,
    };
  },
  computed: {
    mainFormAvailable() {
      return this.accessType !== null &&
          (((this.checkLocation !== null && this.checkType === 0) || this.checkType === 1) ||
              this.accessType !=='auto');
    },
  },
  async mounted() {
    const response = await driverCheckLocations({});
    if (response && response.status === 200) {
      this.driverCheckLocations = response.data;
    }
    const responseCont = await contractorRead(this.$store.state.user.ownerId);
    if (responseCont && responseCont.status === 200) {
      this.expressDriverCheck = responseCont.data.expressDriverCheck;
      if (!this.expressDriverCheck) {
        this.accessType = 'auto';
        this.checkType = 0;
      }
    }
  },
  methods: {
    clearCheckType() {
      this.checkType = null;
      this.checkLocation = null;
      if (!this.expressDriverCheck) {
        this.checkType = 0;
      }
    },
  },
};
</script>

<style scoped>

</style>
